export default [
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/Settings.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  // BANK HOLIDAYS
  {
    path: "/settings/bank-holidays",
    name: "settings-bank-holidays",
    component: () => import("@/views/settings/BankHolidays/BankHolidays.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/actions-timeline",
    name: "settings-actions-timeline",
    component: () => import("@/views/settings/Actions/Timeline.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  // PERMISSIONS
  {
    path: "/settings/userrole",
    name: "settings-userrole",
    component: () =>
      import("@/views/settings/Permissions/role-list/RoleList.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  // TASKS
  {
    path: "/settings/settings-dashboard/tasks",
    name: "settings-tasks",
    component: () => import("@/views/settings/Task/Task.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  {
    path: "/settings/settings-dashboard/task-add",
    name: "settings-task-add",
    component: () => import("@/views/settings/Task/TaskAdd.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  {
    path: "/settings/settings-dashboard/task-edit/:id",
    name: "settings-task-edit",
    component: () => import("@/views/settings/Task/TaskEdit.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  // DEPARTMENTS
  {
    path: "/settings/settings-dashboard/departments",
    name: "settings-departments",
    component: () => import("@/views/settings/Department/Department.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  {
    path: "/settings/settings-dashboard/department-add",
    name: "settings-department-add",
    component: () => import("@/views/settings/Department/DepartmentAdd.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  {
    path: "/settings/settings-dashboard/department-edit/:id",
    name: "settings-department-edit",
    component: () => import("@/views/settings/Department/DepartmentEdit.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  // SITES
  {
    path: "/settings/settings-dashboard/sites",
    name: "settings-sites",
    component: () => import("@/views/settings/Site/Site.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  {
    path: "/settings/settings-dashboard/site-add",
    name: "settings-site-add",
    component: () => import("@/views/settings/Site/SiteAdd.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  {
    path: "/settings/settings-dashboard/site-edit/:id",
    name: "settings-site-edit",
    component: () => import("@/views/settings/Site/SiteEdit.vue"),
    meta: {
      Permission: "no_permission_required",
      navActiveLink: "settings-dashboard",
    },
  },

  {
    path: '/helpdesk',
    name: 'helpdesk',
    component: () => import('@/views/settings/Documentation.vue'),
    props: true, // Enable passing props via route
    meta: {
      Permission: 'no_permission_required',
      layout: 'full',
      resource: 'Auth',
    },
  },


  {
    path: "/settings/emails-test",
    name: "settings-emails-test",
    component: () => import('@/views/settings/EmailsTest/EmailsTest.vue'),
    meta: {
      Permission: "no_permission_required",
    },
  },

];
