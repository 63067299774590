import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken && accessToken!='undefined') {

          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
          const expiresIn = this.getTokenExpiry()
          if (expiresIn < 0) {
            this.logoutLocal()
          }
          if (expiresIn / 60 < 20) {
            //   this.refreshToken()
            // router.go()
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken().then(r => {
                this.isAlreadyFetchingAccessToken = false

                // Update accessToken in localStorage
                this.setToken(r.data.accessToken)
                this.setRefreshToken(r.data.accessToken)

                this.onAccessTokenFetched(r.data.accessToken)
              })
            }
          }
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        const originalRequest = config

        // Handle 401 errors and token refresh
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            return this.refreshToken().then(() => {
              this.isAlreadyFetchingAccessToken = false
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${this.getToken()}`
              return this.axiosIns(originalRequest)
            }).catch(err => {
              this.handleTokenRefreshError()
              return Promise.reject(err) // Reject the error to handle it elsewhere if needed
            })
          }
          this.logoutLocal()
        }
        // If the error is not 401 or refresh failed, handle it normally
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }


  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getTokenSetTime() {
    const tokenSetTime = localStorage.getItem(this.jwtConfig.storageTokenSetTimeName)

    return tokenSetTime
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    const setTime = new Date()

    localStorage.setItem(this.jwtConfig.storageTokenSetTimeName, setTime.getTime())
  }

  setTokenExpiry(value) {
    localStorage.setItem(this.jwtConfig.storageTokenExpiryKeyName, value)
  }

  getTokenExpiry() {
    const expiry = localStorage.getItem(this.jwtConfig.storageTokenExpiryKeyName) // Token expiry in seconds
    const tokenSetTime = this.getTokenSetTime() // Time when the token was set

    if (!expiry || !tokenSetTime) {
      return 0 // Return 0 if expiry or tokenSetTime is not available
    }

    const now = new Date().getTime() // Current time in milliseconds

    // Calculate the expiration time in milliseconds by adding the expiry duration to the token set time
    const expiryTime = parseInt(tokenSetTime, 10) + parseInt(expiry, 10) * 1000

    // Calculate the remaining time until the token expires
    const remainingTokenMillis = expiryTime - now

    return Math.floor(remainingTokenMillis / 1000) // Return remaining time in seconds
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }


  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  removeTokens() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  logoutServer() {
    this.axiosIns.post(this.jwtConfig.logoutEndpoint).then(() => {
      this.logoutLocal()
    }).catch(() => {
      this.logoutLocal()
    })
  }

  logoutLocal() {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageTokenExpiryKeyName)
    localStorage.removeItem(this.jwtConfig.storageTokenSetTimeName)
    // Remove userData from localStorage
    localStorage.removeItem('userData')



    // Redirect to login page
    router.go()
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    }).then(response => {
      // If refresh was successful, resolve with new tokens
      this.setToken(response.data.accessToken)
      this.setRefreshToken(response.data.accessToken)
      this.onAccessTokenFetched(response.data.accessToken)
      this.setTokenExpiry(response.data.expireTime)
      return response
    }).catch(error => {
      // Only log out if we get a specific error response from the server, like 401 Unauthorized or token expired
      if (error.response && error.response.status === 401) {
        this.handleTokenRefreshError()
      }
    })
  }

  handleTokenRefreshError() {
    this.$bvToast.toast(this.$t('Session timeout. Redirecting to login page...'), {
      title: this.$t('Session timeout'),
      variant: 'danger',
      solid: false,
    })

    // Clear tokens and user data from localStorage
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageTokenExpiryKeyName);
    localStorage.removeItem(this.jwtConfig.storageTokenSetTimeName);
    localStorage.removeItem('userData')



    // Redirect to login page
    router.push({ name: 'auth-login' })
  }
}